<template>
   <div>
      <h1>评估疗效指标测验报告(修改)</h1>
      <h4>
         提示：此修改仅为指标测验报告信息修改，如果需要修改患者信息，前往患者列表或查询患者处修改
      </h4>
      <el-button type="primary"
                 @click="updateBtn"
                 plain>确定修改</el-button>
      <table v-if="this.update.cname">
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center"
                class="inputDeep">
               姓名：
               <el-input v-model="update.cname" />
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center">
               就诊卡号：
               <el-input v-model="update.cid" />
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center">
               科室：
               <el-input v-model="update.section" />
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center">
               指导医生：
               <el-input v-model="update.doctor" />
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="10"
                style="text-align: center">
               报告时间：{{ this.update.dateTime.replace("T", "-") }}
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               序号
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               项目
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               评分
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               参考诊断
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               1
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               {{ this.update.etName }}
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               {{ this.update.score }}
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               {{ this.update.solution }}
            </td>
         </tr>
      </table>

      <table id="table2">
         <div style="text-align: left">
            <p>测验结论：</p>
            <el-input v-model="update.review" />
         </div>
      </table>
   </div>
</template>

<script>
import { Message } from "element-ui";
import api from "../../http/api";
import { createNamespacedHelpers } from "vuex";
import axios from "../../http/axios";
const { mapState, mapActions } = createNamespacedHelpers("patient");
export default {
   name: "Dashboard",
   data () {
      return {
         update: {},
         cname: "",
         CID: "",
      };
   },
   created () {
      this.getOneReport();
      // console.log("update", this.update);
   },
   updated () {
      // console.log("结论", this.update.review);
      // console.log("姓名", this.update.cname);
   },
   mounted () {
      // console.log(11111111111, this.$route.params);
   },

   computed: {
      cid () {
         return this.$route.params.cid;
      },
      id () {
         return this.$route.params.id;
      },
      // 睡眠觉醒类型
      MEQ () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            if (data) {
               var arr = data.split(",");
               let meq = arr.map(Number)[2];
               return meq;
            } else {
               return "/";
            }
         }
      },
      // 睡眠综合评分
      Sleep () {
         if (this.update.etId !== "E6") {
            return "你还没有做该项评估";
         } else {
            return this.update.score;
         }
      },
      Sleep_text () {
         if (this.update.etId !== "E6") {
            return "你还没有做该项评估";
         } else {
            return this.update.solution;
         }
      },
      // 焦虑评估
      BAI () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            if (data) {
               var arr = data.split(",");
               let bai = arr.map(Number)[3];
               return bai;
            } else {
               return "/";
            }
         }
      },
      // 抑郁评估
      HAMD () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            if (data) {
               var arr = data.split(",");
               let hamd = arr.map(Number)[4];
               return hamd;
            } else {
               return "/";
            }
         }
      },
      // 睡眠质量
      Sleep_quality () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            if (data) {
               var arr = data.split(",");
               let a = arr.map(Number)[0];
               return a;
            } else {
               return "/";
            }
         }
      },
      // 睡眠障碍
      Sleep_disorders () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            if (data) {
               var arr = data.split(",");
               let a = arr.map(Number)[1];
               return a;
            } else {
               return "/";
            }
         }
      },
      // 睡眠觉醒类型诊断参考
      MEQText () {
         if (this.MEQ < 23) {
            return "分数不正常，请重新评估";
         }
         if (this.MEQ >= 23 && this.MEQ <= 26) {
            return "绝对 “夜晚”型";
         }
         if (this.MEQ >= 27 && this.MEQ <= 30) {
            return "绝对 “夜晚”型";
         }
         if (this.MEQ >= 31 && this.MEQ <= 34) {
            return "中度 “夜晚”型";
         }
         if (this.MEQ >= 35 && this.MEQ <= 38) {
            return "中度 “夜晚”型";
         }
         if (this.MEQ >= 39 && this.MEQ <= 41) {
            return "中度 “夜晚”型";
         }
         if (this.MEQ >= 42 && this.MEQ <= 45) {
            return "中间型";
         }
         if (this.MEQ >= 46 && this.MEQ <= 49) {
            return "中间型";
         }
         if (this.MEQ >= 50 && this.MEQ <= 53) {
            return "中间型";
         }
         if (this.MEQ >= 54 && this.MEQ <= 57) {
            return "中间型";
         }
         if (this.MEQ >= 58 && this.MEQ <= 61) {
            return "中度 “清晨”型";
         }
         if (this.MEQ >= 62 && this.MEQ <= 65) {
            return "中度 “清晨”型";
         }
         if (this.MEQ >= 66 && this.MEQ <= 69) {
            return "中度 “清晨”型";
         }
         if (this.MEQ >= 70 && this.MEQ <= 72) {
            return "绝对 “清晨”型";
         }
         if (this.MEQ >= 72 && this.MEQ <= 86) {
            return "中度 “清晨”型";
         }
      },
      BAIText () {
         if (this.BAI < 15) {
            return "无焦虑";
         }
         if (this.BAI >= 15 && this.BAI <= 25) {
            return "轻度焦虑";
         }
         if (this.BAI > 26 && this.BAI <= 35) {
            return "中度焦虑";
         }
         if (this.BAI >= 36) {
            return "重度焦虑";
         }
      },
      HAMDText () {
         if (this.HAMD < 8) {
            return "正常";
         }
         if (this.HAMD >= 8 && this.HAMD <= 20) {
            return "可能有抑郁症";
         }
         if (this.HAMD > 20 && this.HAMD <= 35) {
            return "肯定有抑郁症";
         }
         if (this.HAMD > 35) {
            return "严重抑郁症";
         }
      },
      Sleep_qualityText () {
         if (this.Sleep_quality == 0) {
            return "很好";
         }
         if (this.Sleep_quality == 1) {
            return "较好";
         }
         if (this.Sleep_quality == 2) {
            return "较差";
         }
         if (this.Sleep_quality == 3) {
            return "很差";
         }
      },
      Sleep_disordersText () {
         if (this.Sleep_disorders == 0) {
            return "无";
         }
         if (this.Sleep_disorders == 1) {
            return "轻度";
         }
         if (this.Sleep_disorders == 2) {
            return "中度";
         }
         if (this.Sleep_disorders == 3) {
            return "重度";
         }
      },
   },

   methods: {
      ...mapActions(["lookRport", "updateReport", "delRport"]),
      async getOneReport (cid, id) {
         const data = await api.patient.lookRportOne(this.cid, this.id);
         if (data.status == 200) {
            this.update = data.data.data[0];
         }
      },

      // 确定修改请求
      async updateBtn () {
         const data = await axios({
            url: `/ExamSolution/Update?_id=${this.update.id}&ETID=${this.update.etId}&ETName=${this.update.etName}&CID=${this.update.cid}&CName=${this.update.cname}&Data=${this.update.data}&Score=${this.update.score}&Solution=${this.update.solution}&Review=${this.update.review}&Section=${this.update.section}&Doctor=${this.update.doctor}`,
            method: "post",
            data,
         });
         if (data) {
            Message.success("修改成功");
            this.lookRport()
            this.$router.push('/system/LookRport')
            // console.log(data);
         }
      },
      // te() {
      //   // console.log(111);
      // },
   },
};
</script>

<style lang="scss" scoped>
table {
   border: 1px solid black;
   border-collapse: collapse;
   margin: 0 auto;
   width: 800px;
}
table td,
th {
   border: 1px black solid;
   background-color: white;
   height: 40px;
   width: 100px;
}
#table2 {
   width: 800px;
   height: 200px;
   div {
      width: 800px;
      height: 200px;
      p {
         // padding: 0;
         margin: 0px;
         padding: 20px 20px;
      }
   }
}
.el-input {
   width: 170px;
}

/* 利用穿透，设置input边框隐藏 */
// .inputDeep >>> .el-input__inner {
//   border: 0;
// }
</style>
